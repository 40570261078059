<template>
    <!--<v-container fluid class="home" fill-height style="max-height: 100vh;">-->
    <v-container fluid class="bonus-background">
        <discount-form v-if="param" :param="param" class=""></discount-form>
        <holder-discounts v-else :paramPagination="true" :paramFilter="true" :paramTitle="true"/>
    </v-container>
</template>

<script>
  import HolderDiscounts from '@/components/HolderDiscounts';
  import DiscountForm from "../components/DiscountForm";

  export default {
    name: 'Discounts',
//    props: {holderType: String},
    props: ['param'],
    components: {
      DiscountForm,
      HolderDiscounts
    },
    mounted() {
      //console.log(this.$route);
      //console.log(this.param);
    },
    methods: {
    }
  };
</script>

<style scoped>
</style>
